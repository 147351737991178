<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-21 18:58:17
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-22 13:52:47
-->
<template>
  <div class="sankey_chart">
    <a-card :border="false">
      <SankeyDiagram ref="SankeyDiagram" :option="option"></SankeyDiagram>
    </a-card>
  </div>
</template>

<script>
import SankeyDiagram from './highcharts/SankeyDiagram.vue'

export default {
  name: 'sankey_chart',

  components: {
    SankeyDiagram
  },
  data() {
    return {
      option: {
        title: {
          subtext: '',
          left: 'center',
        },
        backgroundColor: '#FFFFFF',
        series: [
          {
            type: 'sankey',
            left: 50.0,
            top: 20.0,
            right: 150.0,
            bottom: 25.0,
            data: [],
            links: [],
            lineStyle: {
              color: 'source',
              curveness: 0.5,
            },
            // itemStyle: {
            //   color: '#333333',
            //   borderColor: '#333333',
            // },
            label: {
              color: 'rgba(0,0,0,0.7)',
              fontFamily: 'Arial',
              fontSize: 10,
            },
          },
        ],
        tooltip: {
          trigger: 'item',
        },
      },
    }
  },

  computed: {},

  created() {
    this.getDataList()
  },

  methods: {
    getDataList() {
      this.axios.get(`/api/firm/statistics/SankeyDiagram`).then((res) => {
        let tmpArr = res.body.dataSourceVOS.map(e => {
          return {
            name: e.name,
            // itemStyle: e.itemStyle
          }
        })
        console.log('tmpArr :>> ', tmpArr);
        this.$set(this.option.series[0], 'data', tmpArr)
        this.$set(this.option.series[0], 'links', res.body.associatedDataSourcesVOS)
        this.$refs.SankeyDiagram.getCharts()
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>